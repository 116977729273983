import React from "react";
import { Link } from "gatsby";

import { Seo } from "../components/seo";
import { LayoutDefault } from "../layouts/default";
import { GridContainer } from "../components/grid/content-blocks/container";
import { GridRow } from "../components/grid/content-blocks/row";

export const NotFoundPage = () => (
  <LayoutDefault>
    <Seo title="404: Not found" />
    <GridContainer>
      <GridRow>
        <h1>NOT FOUND</h1>
        <p>
          Oops, looks like that page does not exists. Return to the{" "}
          <Link to="/">home page</Link>
        </p>
      </GridRow>
    </GridContainer>
  </LayoutDefault>
);

export default NotFoundPage;
